import { Box, Text, HStack, SkeletonText, useMediaQuery, useDisclosure, Center, Icon, Tooltip } from "@chakra-ui/react";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useBookingCountPerDayByYearMonth, useBookingsPerYearMonth } from "../../hooks/api/booking";
import { DayPickerSingleDateController } from 'react-dates';
import { omit } from 'lodash';
import { BookingsSummaryTable } from "../modals/CreateBooking/BookingsSummaryTable";
import { useFormContext } from "react-hook-form";
import { FaInfoCircle, FaStar } from "react-icons/fa";
import { UnlockIcon } from "@chakra-ui/icons";

export interface DayPickerProps {
    setDatesPayload?: (val: any) => void;
    datesPayload?: { maxCapacity: number; } | {};
    selectedDays?: any
}

function formatDate(date: Moment | number): string {
    return moment(date).format('YYYY-MM-DD');
}
export const DayPicker: React.FC<DayPickerProps> = ({ setDatesPayload, datesPayload, selectedDays }) => {
    const [yearMonth, setYearMonth] = useState(moment(Date.now()).format('YYYY-MM'));
    const [selectedDate, setSelectedDate] = useState(null);

    const { bookings, isLoadingBookings } = useBookingsPerYearMonth(yearMonth);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isMobile] = useMediaQuery('(max-width: 724px)')

    if (isLoadingBookings) {
        return (
            <HStack>
                <Box padding='6' boxShadow='base' bg='white' height={460} width={1300} borderRadius={'lg'}>
                    <SkeletonText mt='6' noOfLines={5} spacing='6' skeletonHeight={'12'} />
                </Box>
                <Box padding='6' boxShadow='base' bg='white' height={460} width={700} borderRadius={'lg'}>
                    <SkeletonText mt='6' noOfLines={5} spacing='6' skeletonHeight={'12'} />
                </Box>
            </HStack>
        )
    }

    const bookingsForSelectedDate = bookings?.filter(booking => selectedDate === booking.acceptedDate);

    const renderPicker = () => {
        return (
            <Box>
                <BookingsSummaryTable selectedDate={selectedDate} selectedDateBookings={bookingsForSelectedDate} isOpen={isOpen} onClose={onClose} />
                <HStack alignItems={'start'}>
                    {/** @ts-ignore */}
                    <DayPickerSingleDateController
                        onNextMonthClick={(date) => {
                            const currentYearMonth = moment(Date.now()).format('YYYYMM');
                            const selectedYearMonth = date.format('YYYYMM');

                            if (selectedYearMonth < currentYearMonth) {
                                return;
                            }
                            setYearMonth(date.format('YYYY-MM'))
                        }}
                        onPrevMonthClick={(date) => {
                            const currentYearMonth = moment(Date.now()).format('YYYYMM');
                            const selectedYearMonth = date.format('YYYYMM');

                            if (selectedYearMonth < currentYearMonth) {
                                return;
                            }
                            setYearMonth(date.format('YYYY-MM'))
                        }}
                        hideKeyboardShortcutsPanel
                        noBorder
                        isDayBlocked={date => date.format('YYYYMMDD') < moment(Date.now()).add(1, 'days').format('YYYYMMDD')}
                        daySize={isMobile ? 42 : 78} // TODO: Make custom hook function to adjust size and other calendar details based on different screen sizes dynamically, to accomodate as much as possible
                        numberOfMonths={1}
                        isDayHighlighted={date => datesPayload[moment(date).format('YYYY-MM-DD')]}
                        renderDayContents={(date) => {
                            const amountOfBookings = bookings?.filter(booking => booking?.acceptedDate === date?.format('YYYY-MM-DD'));
                            const isSelectedDay = selectedDays?.some(selectedDate => selectedDate === date?.format('YYYY-MM-DD'))
                            const isTodaysDate = date.format('YYYYMMDD') === moment(Date.now()).format('YYYYMMDD')
                            const isLateDate = date.format('YYYYMMDD') > moment(Date.now()).add(14, 'days').format('YYYYMMDD')

                            return (
                                <Box position={'relative'}>
                                    <Text>{date.date()}</Text>
                                    {isSelectedDay && (
                                        <Box pos={'absolute'} top={-6} right={1}>
                                            <Icon as={FaStar} fontSize={16} color={'teal.200'} />
                                        </Box>
                                    )}
                                    {isTodaysDate && (
                                        <Box pos={'absolute'} top={-6} right={1}>
                                            <Tooltip hasArrow placement="top" label="Du kan inte skicka en offert för dagens datum.">
                                                <HStack>
                                                    <Icon as={FaInfoCircle} fontSize={14} color={'teal.700'} />
                                                </HStack>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    {isLateDate && (
                                        <Box 
                                            pos={'absolute'} 
                                            top={0}
                                            left={0}
                                            right={0}
                                            bottom={0}
                                            overflow="hidden"
                                        >
                                            <Tooltip hasArrow placement="top" label="Det här datumet har en lägre sannolikhet att bli accepterat av kunden.">
                                                <Box 
                                                    w="full" 
                                                    h="full"
                                                    position="relative"
                                                    _after={{
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        background: `repeating-linear-gradient(
                                                            45deg,
                                                            rgba(160, 174, 192, 0.2),  /* gray.400 with opacity */
                                                            rgba(160, 174, 192, 0.2) 5px,
                                                            rgba(160, 174, 192, 0.3) 5px,
                                                            rgba(160, 174, 192, 0.3) 10px
                                                        )`,
                                                        zIndex: 1
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                    {date.isSameOrAfter(moment(Date.now()).format('YYYY-MM-DD')) && amountOfBookings?.length > 0 && (
                                        <Center onClick={(e) => {
                                            onOpen()
                                            setSelectedDate(moment(date).format('YYYY-MM-DD'))
                                            e.stopPropagation()
                                        }} bg={'messenger.500'} w={[4, 7]} h={[4, 7]} position={'absolute'} borderRadius={'full'} top={[4, 5]} _hover={{ bg: 'messenger.700', border: '1px solid', borderColor: 'messenger.400' }}>
                                            <Text fontSize={isMobile ? 'x-small' : 'sm'} color={'white'}>{amountOfBookings.length}</Text>
                                        </Center>
                                    )}
                                </Box>
                            )
                        }}
                        onDateChange={date => {
                            const changedDate = moment(date).format('YYYY-MM-DD');

                            if (!datesPayload[changedDate]) {
                                setDatesPayload({ ...datesPayload, [changedDate]: {} })
                                return;
                            }
                            setDatesPayload(omit(datesPayload, [changedDate]));
                            return;
                        }}
                    />
                </HStack>
            </Box>
        )
    }

    return renderPicker();
}

export const DayPickerSingleSelect: React.FC = () => {
    const [yearMonth, setYearMonth] = useState(moment(Date.now()).format('YYYY-MM'));
    const [selectedDate, setSelectedDate] = useState(null);
    const { setValue } = useFormContext(); // Add selected to context

    const { bookings, isLoadingBookings } = useBookingsPerYearMonth(yearMonth);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isMobile] = useMediaQuery('(max-width: 724px)')

    if (isLoadingBookings) {
        return (
            <HStack>
                <Box padding='6' boxShadow='base' bg='white' height={460} width={1300} borderRadius={'lg'}>
                    <SkeletonText mt='6' noOfLines={5} spacing='6' skeletonHeight={'12'} />
                </Box>
                <Box padding='6' boxShadow='base' bg='white' height={460} width={700} borderRadius={'lg'}>
                    <SkeletonText mt='6' noOfLines={5} spacing='6' skeletonHeight={'12'} />
                </Box>
            </HStack>
        )
    }

    const bookingsForSelectedDate = bookings?.filter(booking => selectedDate === booking.acceptedDate);
    const unlockedDaysBack = 10
    const renderPicker = () => {
        return (
            <Box>
                <BookingsSummaryTable selectedDate={selectedDate} selectedDateBookings={bookingsForSelectedDate} isOpen={isOpen} onClose={onClose} />
                <HStack alignItems={'start'}>
                    {/** @ts-ignore */}
                    <DayPickerSingleDateController
                        onNextMonthClick={(date) => {
                            const currentYearMonth = moment(Date.now()).format('YYYYMM');
                            const selectedYearMonth = date.format('YYYYMM');

                            if (selectedYearMonth < currentYearMonth) {
                                return;
                            }
                            setYearMonth(date.format('YYYY-MM'))
                        }}
                        onPrevMonthClick={(date) => {
                            const currentYearMonth = moment(Date.now()).format('YYYYMM');
                            const selectedYearMonth = date.format('YYYYMM');

                            if (selectedYearMonth < currentYearMonth) {
                                return;
                            }
                            setYearMonth(date.format('YYYY-MM'))
                        }}
                        hideKeyboardShortcutsPanel
                        noBorder
                        isDayBlocked={date => date.format('YYYYMMDD') < moment(Date.now()).subtract(unlockedDaysBack, 'days').format('YYYYMMDD')}
                        daySize={isMobile ? 42 : 78} // TODO: Make custom hook function to adjust size and other calendar details based on different screen sizes dynamically, to accomodate as much as possible
                        numberOfMonths={1}
                        isDayHighlighted={date => {
                            return selectedDate === formatDate(date);

                        }}
                        renderDayContents={(date) => {
                            const amountOfBookings = bookings?.filter(booking => booking?.acceptedDate === date?.format('YYYY-MM-DD'));
                            const isDayPassed = date.format('YYYYMMDD') >= moment(Date.now()).subtract(unlockedDaysBack, 'days').format('YYYYMMDD') && date.format('YYYYMMDD') < moment(Date.now()).format('YYYYMMDD')
                            const isNotificationDisabled = date.format('YYYYMMDD') >= moment(Date.now()).subtract(unlockedDaysBack, 'days').format('YYYYMMDD') && date.format('YYYYMMDD') < moment(Date.now()).subtract(1, 'days').format('YYYYMMDD')
                            return (
                                <Box position={'relative'}>
                                    {isDayPassed && (
                                        <Tooltip placement="top" label={`Det är möjligt att lägga till bokningar i efterhand för detta datum. ${isNotificationDisabled ? 'Observera att ingen bekräftelse kommer skickas till kund det här datumet.' : ''}`} hasArrow bg="teal.700">
                                            <Box
                                                position="absolute"
                                                left={0}
                                                top={isMobile ? 2 : 6}
                                                zIndex={10}
                                            >
                                                <UnlockIcon />
                                            </Box>
                                        </Tooltip>
                                    )}
                                    <Text>{date.date()}</Text>
                                    {date.isSameOrAfter(formatDate(Date.now())) && amountOfBookings?.length > 0 && (
                                        <Center onClick={(e) => {
                                            onOpen()
                                            setSelectedDate(formatDate(date));
                                            e.stopPropagation()
                                        }} bg={'messenger.500'} w={isMobile ? 4 : 7} h={isMobile ? 4 : 7} position={'absolute'} borderRadius={'full'} top={isMobile ? 4 : 5} _hover={{ bg: 'messenger.700', border: '1px solid', borderColor: 'messenger.400' }}>
                                            <Text fontSize={isMobile ? 'x-small' : 'sm'} color={'white'}>{amountOfBookings.length}</Text>
                                        </Center>
                                    )}
                                </Box>
                            )
                        }}
                        onDateChange={date => {
                            const changedDate = formatDate(date);
                            setValue('selectedDate', changedDate)
                            setSelectedDate(changedDate)
                            return;
                        }}
                    />
                </HStack>
            </Box>
        )
    }

    return renderPicker();
}

export const SimpleDayPicker: React.FC<{
    setSelectedDate: (val: string) => void;
    onClose: Function;
}> = ({ setSelectedDate, onClose }) => {
    const [yearMonth, setYearMonth] = useState(moment(new Date()).format('YYYY-MM'));
    const { countData } = useBookingCountPerDayByYearMonth(yearMonth)
    const [isMobile] = useMediaQuery('(max-width: 724px)')

    return (
        <>
            {/** @ts-ignore */}
            <DayPickerSingleDateController
                hideKeyboardShortcutsPanel
                daySize={isMobile ? 40 : 48} // TODO: Make custom hook function to adjust size and other calendar details based on different screen sizes dynamically, to accomodate as much as possible
                noBorder
                onNextMonthClick={(date) => {
                    setYearMonth(date.format('YYYY-MM'))
                }}
                onPrevMonthClick={(date) => {
                    setYearMonth(date.format('YYYY-MM'))
                }}
                isDayHighlighted={date => {
                    return formatDate(date) === moment(new Date()).format('YYYY-MM-DD')
                }}
                renderDayContents={(date) => {
                    if (!countData) {
                        return
                    }
                    const amountOfBookings = countData[date?.format('YYYY-MM-DD')] || 0;

                    return (
                        <Box position={'relative'}>
                            <Text>{date.date()}</Text>
                            <Center bg={amountOfBookings > 0 ? 'messenger.500' : 'messenger.50'} w={4} h={4} position={'absolute'} borderRadius={'full'} top={isMobile ? 4 : 5}>
                                <Text fontSize={isMobile ? 'x-small' : 'x-small'} color={'white'}>{amountOfBookings}</Text>
                            </Center>
                        </Box>
                    )
                }}
                onDateChange={date => {
                    const changedDate = formatDate(date);
                    setSelectedDate(changedDate)
                    onClose();
                }}
            />
        </>
    )
}
