import { Stack, HStack, Flex, Text, Box, Tooltip, Icon, Heading, Center } from "@chakra-ui/react"
import { OfferEnquiry } from "../Marketplace/types"
import { FaCalendarAlt, FaInfoCircle } from "react-icons/fa";

const CustomerDatePreference = ({ enquiry, showTooltip = true }: { enquiry: OfferEnquiry, showTooltip?: boolean }) => {
    return (
        <HStack spacing={4} pb={2}>
            <Center bg={'gray.100'} w={6} h={6} borderRadius={'full'}>
                <Icon as={FaCalendarAlt} fontSize={12} color={'teal.600'} />
            </Center>
            <Stack spacing={1} pt={2}>
                <Text fontSize={14}>Kunden har önskat följande inlämningsdatum</Text>
                {enquiry?.selectedDays?.length === 0 ? (
                    <HStack>
                        <Heading fontSize={14}>Tidigast möjligt</Heading>
                        <Text fontSize={12}> (inom 1-14 dagar)</Text>
                        {showTooltip && (
                            <Tooltip label='Vi rekommenderar att du skickar ett inlämningsdatum inom 1-14 dagar för att öka chansen att få jobbet.'>
                                <HStack>
                                    <Icon as={FaInfoCircle} fontSize={14} color={'teal.700'} />
                                </HStack>
                            </Tooltip>
                        )}
                    </HStack>
                ) : (
                    <Flex wrap={'wrap'} width={'auto'} >
                        {enquiry?.selectedDays?.map((day, index) => (
                            <Box key={index} padding={'2px'}>
                                <Heading fontSize={14}>{day},</Heading>
                            </Box>
                        ))}
                    </Flex>
                )}
            </Stack>
        </HStack>
    )
}

export default CustomerDatePreference;